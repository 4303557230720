import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import Footer from '../Footer/Footer';

import {
  // BannerDiv,
  // TextCard,
  // TitleImageCard,
  // OneToNTitleImageCard,
  TextCardLeft,
  // TitleImageCarouselCard,
  // SubBannerDivCard,
} from '../../shared/components/roland/RolandModules';

const Terms = ({ t }) => (
  <Container>

    <Row style={{ backgroundColor: '#34b9cc' }}>
      <Col md={12} lg={12} xl={12} xs={12} className="landing__code div_worldcup_cn">
        <h2 className="title">{t('worldcup_terms.title')}</h2>
      </Col>
    </Row>

    <TextCardLeft
      textArray={[
        {
          header: t('worldcup_terms.body.headerA'),
          lines: [
            t('worldcup_terms.body.lines.lineA1'),
            t('worldcup_terms.body.lines.lineA2'),
            t('worldcup_terms.body.lines.lineA3'),
            t('worldcup_terms.body.lines.lineA4'),
            t('worldcup_terms.body.lines.lineA5'),
          ],
        },
        {
          header: t('worldcup_terms.body.headerB'),
          lines: [
            t('worldcup_terms.body.lines.lineB1'),
            t('worldcup_terms.body.lines.lineB2'),
            t('worldcup_terms.body.lines.lineB3'),
            t('worldcup_terms.body.lines.lineB4'),
            t('worldcup_terms.body.lines.lineB5'),
            t('worldcup_terms.body.lines.lineB6'),
          ],
        },
      ]}
    />
    <Footer extra={null} />

  </Container>
);

Terms.propTypes = {
  t: PropTypes.func.isRequired,
};

export default (withTranslation('common')(Terms));
