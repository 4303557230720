import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import MainWrapper from './MainWrapper';
import Layout from '../Layout';

import Home from '../Home';
import AboutUs from '../AboutUs';
import LearnMore from '../LearnMore';
// import Copyright from '../Copyright';
import LegalStatements from '../LegalStatements';
import Terms from '../Terms';
import TermsBible from '../Termsbible';
import VideoPlayer from '../VideoPlayer';
import JoinTemp from '../JoinTemp';
import MemberShips from '../Memberships';
import FormSuccess from '../FormSuccess';
import { DynamicPage } from '../Page';

const wrappedRoutes = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Switch>
        <Route exact path="/home" component={Home} />
        {/* <Route exact path="/worldcup" component={WorldCup} />
        <Route exact path="/worldcupen" component={WorldCupEn} />
        <Route exact path="/worldcupcn" component={WorldCupCn} />
        <Route exact path="/worldcupmulti" component={WorldCupMulti} />
        <Route exact path="/worldcupbible" component={WorldCupBible} /> */}
        <Route exact path="/about" component={AboutUs} />
        <Route exact path="/learnmore" component={LearnMore} />
        <Route
          exact
          path="/privacy"
          render={() => <Redirect exact to="/privacy-policy" />}
        />
        {/* <Route exact path="/copyright" component={Copyright} /> */}
        <Route exact path="/legalstatements" component={LegalStatements} />
        <Route exact path="/terms" component={Terms} />
        <Route exact path="/termsbible" component={TermsBible} />
        <Route exact path="/videoplayer" component={VideoPlayer} />
        <Route exact path="/join" component={JoinTemp} />
        <Route exact path="/memberships" component={MemberShips} />
        <Route exact path="/form-success" component={FormSuccess} />
        <Route exact path="/:pageId+" component={DynamicPage} />
        <Route path="" render={() => <Redirect exact to="/home" />} />
      </Switch>
    </div>
  </div>
);

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        {/* pages from above don't show topbar, otherwise wrap routes */}
        <Route path="/" component={wrappedRoutes} />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
