import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';

const TitleImageVideoCard = ({
  title, subtitle, image, mobileImage, blockColor, video, thumbnail, btnText, btnIntlLink, btnExtlLink, sm, md, lg,
}) => (
  <Row style={{ backgroundColor: blockColor ? `${blockColor}` : '#FFFFFF' }}>
    <Col sm={(12 - sm) / 2} md={(12 - md) / 2} lg={(12 - lg) / 2} />

    <Col sm={sm} md={md} lg={lg}>
      <Card className="normal-card">
        <CardBody style={{ backgroundColor: blockColor ? `${blockColor}` : '#FFFFFF' }}>
          {title ? <h2 className="title">{title}</h2> : null}
          {subtitle ? <h3 className="subtitle">{subtitle}</h3> : null}

          {image ?
            <img
              className="normal-image"
              src={`${process.env.PUBLIC_URL}/img/${image}`}
              alt={title}
            />
            : null}

          {mobileImage ?
            <img
              className="mobile-image"
              src={`${process.env.PUBLIC_URL}/img/${mobileImage}`}
              alt={title}
            /> :
            null}

          <div className="player-div">
            <div className="player-wrapper">
              <ReactPlayer
                className="react-player"
                url={`${process.env.PUBLIC_URL}/img/${video}`}
                width="100%"
                height="100%"
                controls
                playing
                light={thumbnail ? `${process.env.PUBLIC_URL}/img/${thumbnail}` : false}
              />
            </div>
          </div>

          {btnText && btnIntlLink ?
            <Link to={btnIntlLink}>
              <button type="button" className="btn btn-primary">
                {btnText}
              </button>
            </Link>
            : null}

          {btnText && btnExtlLink ?
            <a href={btnExtlLink} target="_blank" rel="noopener noreferrer">
              <button type="button" className="btn btn-primary">
                {btnText}
              </button>
            </a>
            : null}

        </CardBody>
      </Card>
    </Col>

    <Col sm={(12 - sm) / 2} md={(12 - md) / 2} lg={(12 - lg) / 2} />
  </Row>
);

TitleImageVideoCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.string,
  blockColor: PropTypes.string,
  mobileImage: PropTypes.string,
  video: PropTypes.string.isRequired,
  thumbnail: PropTypes.string,
  btnText: PropTypes.string,
  btnIntlLink: PropTypes.string,
  btnExtlLink: PropTypes.string,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
};

TitleImageVideoCard.defaultProps = {
  title: null,
  subtitle: null,
  image: null,
  mobileImage: null,
  blockColor: null,
  thumbnail: null,
  btnText: null,
  btnIntlLink: null,
  btnExtlLink: null,
  sm: 12,
  md: 12,
  lg: 12,
};

export default TitleImageVideoCard;
