import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { withTranslation } from 'react-i18next';

import { Page } from '../../shared/components/page';
import Footer from '../Footer/Footer';

const StaticPage = ({ i18n, pageId, manifest }) => {
  useEffect(() => {
    Object.entries(manifest.translations).forEach(([lng, resources]) => {
      if (!i18n.hasResourceBundle(lng, pageId)) {
        i18n.addResourceBundle(lng, pageId, resources);
      }
    });
  });

  return (
    <Page
      path={`${process.env.PUBLIC_URL}/${pageId}`}
      t={i18n.getFixedT(null, pageId)}
      cards={manifest.cards}
      footer={<Footer />}
    />
  );
};

StaticPage.propTypes = {
  i18n: PropTypes.shape({
    addResourceBundle: PropTypes.func.isRequired,
    getFixedT: PropTypes.func.isRequired,
  }).isRequired,
  pageId: PropTypes.string.isRequired,
  manifest: PropTypes.shape({
    cards: PropTypes.array,
    translations: PropTypes.object,
  }).isRequired,
};

export default withTranslation()(StaticPage);
