import React from 'react';
import { PropTypes } from 'prop-types';

const CheckboxField = ({
  input, meta: { touched, error }, t, label,
}) => (
  <div className="form__input-wrap">
    {/* eslint-disable-next-line jsx-a11y/label-has-for */}
    <label className="form__checkbox-container">
      <input {...input} type="checkbox" />
      <span className="form__checkmark" />
      <span className="form__label">{label}</span>
    </label>
    {touched && error && <span className="form__error">{t(error)}</span>}
  </div>
);

CheckboxField.propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  t: PropTypes.func.isRequired,
  label: PropTypes.element.isRequired,
};

export default CheckboxField;
