import React from 'react';
import { Field } from 'redux-form';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import CheckboxField from './CheckboxField';

export default function transformTerms(
  t,
  {
    fieldName, missingErrorText, termsTranslationKey, termsUrl,
  },
) {
  return {
    fieldName,
    validate: values =>
      (!values[fieldName] && missingErrorText
        ? { [fieldName]: missingErrorText }
        : {}),
    submit: () => {},
    component: (
      <div className="form__form-group">
        <Field
          name={fieldName}
          component={CheckboxField}
          t={t}
          label={
            <Trans i18nKey={termsTranslationKey} t={t}>
              You are agreeing to
              <Link to={termsUrl} target="_blank">
                Terms and Conditions
              </Link>
              .
            </Trans>
          }
        />
      </div>
    ),
  };
}
