import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';

const TitleDoubleImagesCard = ({
  title, subtitle, left, right, sm, md, lg,
}) => (
  <Row>
    <Col sm={(12 - sm) / 2} md={(12 - md) / 2} lg={(12 - lg) / 2} />

    <Col sm={sm} md={md} lg={lg}>
      <Card className="normal-card">
        <CardBody>
          <h2 className="title">{title}</h2>
          {subtitle ? <h3 className="subtitle">{subtitle}</h3> : null}

          <img
            className="left-image"
            src={`${process.env.PUBLIC_URL}/img/${left}`}
            alt={title}
          />

          <img
            className="right-image"
            src={`${process.env.PUBLIC_URL}/img/${right}`}
            alt={title}
          />

        </CardBody>
      </Card>
    </Col>

    <Col sm={(12 - sm) / 2} md={(12 - md) / 2} lg={(12 - lg) / 2} />
  </Row>
);

TitleDoubleImagesCard.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  left: PropTypes.string.isRequired,
  right: PropTypes.string.isRequired,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
};

TitleDoubleImagesCard.defaultProps = {
  subtitle: null,
  sm: 12,
  md: 12,
  lg: 12,
};

export default TitleDoubleImagesCard;
