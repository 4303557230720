import React from 'react';
import { PropTypes } from 'prop-types';
import Select from 'react-select';

const SelectField = ({
  input,
  meta: { touched, error },
  t,
  options,
  placeholder,
}) => (
  <div className="form__input-wrap">
    <Select
      {...input}
      className="form__select"
      options={options}
      placeholder={placeholder}
      clearable={false}
      onChange={value => input.onChange(value ? value.value : null)}
      onBlur={() => input.onBlur()}
    />
    {touched && error && <span className="form__error">{t(error)}</span>}
  </div>
);

SelectField.propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  t: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  placeholder: PropTypes.string,
};

SelectField.defaultProps = {
  placeholder: '',
};

export default SelectField;
