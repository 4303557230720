import React from 'react';
import { Container } from 'reactstrap';
import Footer from '../Footer/Footer';

import {
  BannerDiv,
  TitleImageCard,
  OneToNTitleImageCard,
  // TitleImageCarouselCard,
} from '../../shared/components/roland/RolandModules';

const LearnMore = () => (
  <Container>

    <BannerDiv
      image="learnmore/dt2.png"
      mobileImage="learnmore/mb2.png"
    />

    <OneToNTitleImageCard
      title="International Certifications for All Participants"
      image="learnmore/dt15.png"
      mobileImage="learnmore/mb15.png"
      mobileImageArray={[
        {
          image: 'learnmore/mb15a.png',
        },
        {
          image: 'learnmore/mb15b.png',
        },
        {
          image: 'learnmore/mb15c.png',
        },
        {
          image: 'learnmore/mb15d.png',
        },
      ]}
      btnText="Join Now"
      btnExtlLink="https://joyajoy.com"
    />

    <OneToNTitleImageCard
      title="International Awards"
      image="learnmore/dt16.png"
      mobileImage="learnmore/mb16.png"
      mobileImageArray={[
        {
          image: 'learnmore/mb16a.png',
        },
        {
          image: 'learnmore/mb16b.png',
        },
        {
          image: 'learnmore/mb16c.png',
        },
      ]}
    />

    <TitleImageCard
      title="DipDip World Cup Bible"
      subtitle="12 Languages for Selection"
      image="learnmore/dt17.png"
      mobileImage="learnmore/mb17.png"
    />

    <TitleImageCard
      title="DipDip World Cup Bible"
      subtitle="Progress"
      image="learnmore/dt18.png"
      mobileImage="learnmore/mb18.png"
      blockColor="#F5F5F5"
      btnText="Join Now!"
      btnExtlLink="https://joyajoy.com"
    />

    <Footer extra={null} />

  </Container>
);

export default LearnMore;
