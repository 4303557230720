import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const TextCardLeft = ({
  textArray, btnText, btnIntlLink, btnExtlLink, sm, md, lg,
}) => (
  <Row>
    <Col sm={(12 - sm) / 2} md={(12 - md) / 2} lg={(12 - lg) / 2} />

    <Col sm={sm} md={md} lg={lg}>
      <Card className="normal-card">
        <CardBody>

          {textArray.map(item => (
            <div key={item.header}>

              <p className="header-left">{item.header}</p>

              {item.lines.map(line => (
                <p className="text-left" key={line}>{line}</p>
              ))}

              <br />

            </div>
          ))}

          {btnText && btnIntlLink ?
            <Link to={btnIntlLink}>
              <button type="button" className="btn btn-primary">
                {btnText}
              </button>
            </Link>
            : null}

          {btnText && btnExtlLink ?
            <a href={btnExtlLink} target="_blank" rel="noopener noreferrer">
              <button type="button" className="btn btn-primary">
                {btnText}
              </button>
            </a>
            : null}

        </CardBody>
      </Card>
    </Col>

    <Col sm={(12 - sm) / 2} md={(12 - md) / 2} lg={(12 - lg) / 2} />
  </Row>
);

TextCardLeft.propTypes = {
  textArray: PropTypes.arrayOf(PropTypes.shape({
    header: PropTypes.string.isRequired,
    lines: PropTypes.arrayOf(PropTypes.string).isRequired,
  })).isRequired,
  btnText: PropTypes.string,
  btnIntlLink: PropTypes.string,
  btnExtlLink: PropTypes.string,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
};

TextCardLeft.defaultProps = {
  btnText: null,
  btnIntlLink: null,
  btnExtlLink: null,
  sm: 12,
  md: 12,
  lg: 12,
};

export default TextCardLeft;
