import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';

const SubBannerDivCard = ({
  title, subtitle, blockColor, sm, md, lg,
}) => (
  <Row style={{ backgroundColor: blockColor ? `${blockColor}` : '#FFFFFF' }}>
    <Col sm={(12 - sm) / 2} md={(12 - md) / 2} lg={(12 - lg) / 2} />

    <Col sm={sm} md={md} lg={lg}>
      <Card className="normal-card">
        <CardBody style={{ backgroundColor: blockColor ? `${blockColor}` : '#FFFFFF' }}>
          <h2 className="header">{title}</h2>
          {subtitle ? <h3 className="text">{subtitle}</h3> : null}
        </CardBody>
      </Card>
    </Col>

    <Col sm={(12 - sm) / 2} md={(12 - md) / 2} lg={(12 - lg) / 2} />

  </Row>
);

SubBannerDivCard.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  blockColor: PropTypes.string,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
};

SubBannerDivCard.defaultProps = {
  subtitle: null,
  blockColor: null,
  sm: 12,
  md: 12,
  lg: 12,
};

export default SubBannerDivCard;
