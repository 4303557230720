import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const ContentButton = ({
  t,
  text,
  url,
  isExternal,
  styleType,
  fontWeight,
  fontSize,
}) =>
  (isExternal ? (
    <a href={url} target="_blank" rel="noopener noreferrer">
      <button
        type="button"
        className={`btn ${styleType}`}
        style={{ fontWeight, fontSize }}
      >
        {t(text)}
      </button>
    </a>
  ) : (
    <Link to={url}>
      <button
        type="button"
        className={`btn ${styleType}`}
        style={{ fontWeight, fontSize }}
      >
        {t(text)}
      </button>
    </Link>
  ));

ContentButton.propTypes = {
  t: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  isExternal: PropTypes.bool,
  styleType: PropTypes.oneOf([
    'btn-primary',
    'btn-secondary',
    'btn-success',
    'btn-warning',
    'btn-danger',
    'btn-etsa-light',
    'btn-etsa-dark',
    'btn-outline-primary',
    'btn-outline-secondary',
    'btn-outline-success',
    'btn-outline-warning',
    'btn-outline-danger',
    'btn-outline-white-pink',
    'btn-outline-white-blue',
  ]),
  // eslint-disable-next-line react/require-default-props
  fontWeight: PropTypes.oneOf(['100', '300', '400', '500', '700', '900']),
  // eslint-disable-next-line react/require-default-props
  fontSize: PropTypes.string,
};

ContentButton.defaultProps = {
  isExternal: false,
  styleType: 'btn-warning',
};

export default ContentButton;
