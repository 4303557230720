import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';

const TitleImageCardCustom = ({
  titleA, titleSpan, titleB, titleType, subtitle, image, mobileImage, blockColor, btnText, btnIntlLink, btnExtlLink, btntype, sm, md, lg,
}) => (
  <Row style={{ backgroundColor: blockColor ? `${blockColor}` : '#FFFFFF' }}>
    <Col sm={(12 - sm) / 2} md={(12 - md) / 2} lg={(12 - lg) / 2} />

    <Col sm={sm} md={md} lg={lg}>
      <Card className="normal-card">
        <CardBody style={{ backgroundColor: blockColor ? `${blockColor}` : '#FFFFFF' }}>
          {titleA && titleSpan && titleB ? <h2 className={titleType}>{titleA} <span style={{ color: '#F4812F' }}>{titleSpan}</span> {titleB}</h2> : null}
          {subtitle ? <h3 className="subtitle">{subtitle}</h3> : null}

          <img
            className="normal-image"
            src={`${process.env.PUBLIC_URL}/img/${image}`}
            alt={titleSpan}
          />

          {mobileImage ?
            <img
              className="mobile-image"
              src={`${process.env.PUBLIC_URL}/img/${mobileImage}`}
              alt={titleSpan}
            /> :
            <img
              className="mobile-image"
              src={`${process.env.PUBLIC_URL}/img/${image}`}
              alt={titleSpan}
            />}

          {btnText && btnIntlLink ?
            <Link to={btnIntlLink}>
              <button type="button" className={btntype}>
                {btnText}
              </button>
            </Link>
            : null}

          {btnText && btnExtlLink ?
            <a href={btnExtlLink} target="_blank" rel="noopener noreferrer">
              <button type="button" className={btntype}>
                {btnText}
              </button>
            </a>
            : null}

        </CardBody>
      </Card>
    </Col>

    <Col sm={(12 - sm) / 2} md={(12 - md) / 2} lg={(12 - lg) / 2} />
  </Row>
);

TitleImageCardCustom.propTypes = {
  titleA: PropTypes.string,
  titleSpan: PropTypes.string,
  titleB: PropTypes.string,
  titleType: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.string.isRequired,
  mobileImage: PropTypes.string,
  blockColor: PropTypes.string,
  btnText: PropTypes.string,
  btnIntlLink: PropTypes.string,
  btnExtlLink: PropTypes.string,
  btntype: PropTypes.string,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
};

TitleImageCardCustom.defaultProps = {
  titleA: null,
  titleSpan: null,
  titleB: null,
  titleType: 'title',
  subtitle: null,
  mobileImage: null,
  blockColor: null,
  btnText: null,
  btnIntlLink: null,
  btnExtlLink: null,
  btntype: 'btn btn-outline-primary',
  sm: 12,
  md: 12,
  lg: 12,
};

export default TitleImageCardCustom;
