import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const ContentImage = ({
  path, t, normal, mobile, text, styleType,
}) => (
  <Fragment>
    <img
      className={`division normal ${
        styleType === 'image-icon' ? 'image' : styleType
      }`}
      src={`${path}/${t(normal)}`}
      alt={t(text)}
    />
    {Array.isArray(mobile) ? (
      mobile.map(image => (
        <div key={image}>
          <img
            className="mobile-images"
            src={`${path}/${t(image)}`}
            alt={t(text)}
          />
        </div>
      ))
    ) : (
      <img
        className={`division mobile ${
          styleType === 'image-icon' ? 'icon' : styleType
        }`}
        src={`${path}/${mobile ? t(mobile) : t(normal)}`}
        alt={t(text)}
      />
    )}
  </Fragment>
);

ContentImage.propTypes = {
  path: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  normal: PropTypes.string.isRequired,
  mobile: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  text: PropTypes.string,
  styleType: PropTypes.oneOf(['image', 'small-image', 'icon', 'image-icon']),
};

ContentImage.defaultProps = {
  mobile: '',
  text: 'Image',
  styleType: 'image',
};

export default ContentImage;
