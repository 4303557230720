import React from 'react';
import { Row } from 'reactstrap';
import PropTypes from 'prop-types';

const BannerDiv = ({
  text, image, mobileImage,
}) => (
  <Row>
    <div className="banner">

      <img
        className="normal"
        src={`${process.env.PUBLIC_URL}/img/${image}`}
        alt={text || ''}
      />

      {mobileImage ?
        <img
          className="mobile"
          src={`${process.env.PUBLIC_URL}/img/${mobileImage}`}
          alt={text || ''}
        />
        :
        <img
          className="mobile"
          src={`${process.env.PUBLIC_URL}/img/${image}`}
          alt={text || ''}
        />}

    </div>

  </Row>
);

BannerDiv.propTypes = {
  text: PropTypes.string,
  image: PropTypes.string.isRequired,
  mobileImage: PropTypes.string,
};

BannerDiv.defaultProps = {
  text: 'Banner',
  mobileImage: null,
};

export default BannerDiv;
