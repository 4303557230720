import React from 'react';
import { Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import Footer from '../Footer/Footer';

const LOGO = `${process.env.PUBLIC_URL}/img/app/AppIcon.png`;
const APP_STORE = `${process.env.PUBLIC_URL}/img/app/app_store.png`;
const PLAY_STORE = `${process.env.PUBLIC_URL}/img/app/play_store.png`;
// const LOGO_MB = `${process.env.PUBLIC_URL}/img/app/AppIcon_mb.png`;
// const APP_STORE_MB = `${process.env.PUBLIC_URL}/img/app/app_store_mb.png`;
// const PLAY_STORE_MB = `${process.env.PUBLIC_URL}/img/app/play_store_mb.png`;
// const facebook = `${process.env.PUBLIC_URL}/img/social/Facebook.png`;
// const twitter = `${process.env.PUBLIC_URL}/img/social/Twitter.png`;
// const youtube = `${process.env.PUBLIC_URL}/img/social/Youtube.png`;

const JoinTemp = ({
  blockColor,
}) => (
  <Container>
    <Row style={{ backgroundColor: blockColor ? `${blockColor}` : '#FFFFFF' }}>
      <div className="landing_div_temp">
        <h2 className="app_get_title">Download DipDip App to Join DipDip World Cup!</h2>
        <img src={LOGO} alt="DipDip" />
        {/* <img className="log_mb_img" src={LOGO_MB} alt="DipDip" /> */}
        <div className="downloads">
          <a href="https://itunes.apple.com/app/dipdip/id1099703101?mt=8" target="_blank" rel="noopener noreferrer">
            <img className="app_store" src={APP_STORE} alt="AppStore" />
            {/* <img className="app_mb_store" src={APP_STORE_MB} alt="AppStore" /> */}
          </a>
          &nbsp;
          <a href="https://play.google.com/store/apps/details?id=com.dipdip" target="_blank" rel="noopener noreferrer">
            <img className="play_store" src={PLAY_STORE} alt="PlayStore" />
            {/* <img className="play_mb_store" src={PLAY_STORE_MB} alt="PlayStore" /> */}
          </a>
        </div>
        {/* <div>
          <a href="https://www.facebook.com/joyAjoyOfficial/" target="_blank" rel="noopener noreferrer">
            <img className="social_footer" src={facebook} alt="facebook" />
          </a>
          <a href="https://twitter.com/joyAjoyOfficial/" target="_blank" rel="noopener noreferrer">
            <img className="social_footer" src={twitter} alt="twitter" />
          </a>
          <a href="https://www.youtube.com/channel/UCaPdW53YFr3exkqMAV0SWKQ" target="_blank" rel="noopener noreferrer">
            <img className="social_footer" src={youtube} alt="youtube" />
          </a>
        </div> */}
      </div>

    </Row>
    <Footer extra={null} />
  </Container>
);

JoinTemp.propTypes = {
  blockColor: PropTypes.string,
};

JoinTemp.defaultProps = {
  blockColor: null,
};

export default JoinTemp;
