import React from 'react';
import { Field } from 'redux-form';

import SelectField from './SelectField';

export default function transformSelect(
  t,
  {
    fieldName, fieldLabel, missingErrorText, options,
  },
) {
  return {
    fieldName,
    validate: values =>
      (!values[fieldName] && missingErrorText
        ? { [fieldName]: missingErrorText }
        : {}),
    submit: values =>
      (values[fieldName] ? { [fieldName]: values[fieldName] } : {}),
    component: (
      <div className="form__form-group">
        <span className="form__label">{t(fieldLabel)}</span>
        <div className="form__field">
          <Field
            name={fieldName}
            component={SelectField}
            t={t}
            options={options.map(({ value, label }) => ({
              value,
              label: t(label),
            }))}
          />
        </div>
      </div>
    ),
  };
}
