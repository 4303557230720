import React, { useState } from 'react';
import { Collapse, Row, Col } from 'reactstrap';
import DownIcon from 'mdi-react/ChevronDownIcon';
import PropTypes from 'prop-types';

import { withLocalization } from '../../../shared/components/Localization';

const CollapseSpan = ({ value, image, label }) => (
  <span className="footer__language-btn-title">
    <img src={image} alt={value} />
    <br />
    <span>{label}</span>
  </span>
);

CollapseSpan.propTypes = {
  value: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

const FooterLanguage = (props) => {
  const {
    regions,
    languages,
    region,
    language,
    onRegionChange,
    onLanguageChange,
    availableRegions = Object.keys(regions),
    availableLanguages = Object.keys(languages),
    overrideRegionChange,
    overrideLanguageChange,
  } = props;

  const regionList = Object.entries(regions)
    .filter(([key]) => availableRegions.includes(key))
    .map(([, value]) => value);

  const languageList = Object.entries(languages)
    .filter(([key]) => availableLanguages.includes(key))
    .map(([, value]) => value);

  const [isRegionOpened, setRegionOpened] = useState(false);
  const [isLanguageOpened, setLanguageOpened] = useState(false);

  // eslint-disable-next-line no-shadow
  const handleRegionSelect = (region) => {
    setRegionOpened(false);
    if (overrideRegionChange) {
      overrideRegionChange(region);
    } else {
      onRegionChange(region);
    }
  };
  // eslint-disable-next-line no-shadow
  const handleLanguageSelect = (language) => {
    setLanguageOpened(false);
    if (overrideLanguageChange) {
      overrideLanguageChange(language);
    } else {
      onLanguageChange(language);
    }
  };

  return (
    <Row>
      <Col lg={3} xl={3} md={0} sm={0} xs={0} />
      <Col
        lg={3}
        xl={3}
        md={6}
        sm={6}
        xs={6}
        className="footer__right footer__collapse--language"
      >
        <div>
          <button
            className="topbar__btn footer-language-btn"
            onClick={() => setRegionOpened(!isRegionOpened)}
          >
            <CollapseSpan {...(regions[region] || regions.US)} />
            <DownIcon className="footer__icon" />
          </button>
          <Collapse
            isOpen={isRegionOpened}
            className="footer__collapse-content footer__collapse-content-left-language"
          >
            {regionList.map(r => (
              <button
                key={r.value}
                className="topbar__language-btn"
                type="button"
                onClick={() => handleRegionSelect(r.value)}
              >
                <CollapseSpan {...r} />
              </button>
            ))}
          </Collapse>
        </div>
      </Col>
      <Col
        lg={3}
        xl={3}
        md={6}
        sm={6}
        xs={6}
        className="footer__left footer__collapse--language"
      >
        <div>
          <button
            className="topbar__btn footer-language-btn"
            onClick={() => setLanguageOpened(!isLanguageOpened)}
          >
            <CollapseSpan {...(languages[language] || languages.en)} />
            <DownIcon className="footer__icon" />
          </button>
          <Collapse
            isOpen={isLanguageOpened}
            className="footer__collapse-content footer__collapse-content-left-language"
          >
            {languageList.map(l => (
              <button
                key={l.value}
                className="topbar__language-btn"
                type="button"
                onClick={() => handleLanguageSelect(l.value)}
              >
                <CollapseSpan {...l} />
              </button>
            ))}
          </Collapse>
        </div>
      </Col>
      <Col lg={3} xl={3} md={0} sm={0} xs={0} />
    </Row>
  );
};

FooterLanguage.propTypes = {
  regions: PropTypes.objectOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
    image: PropTypes.string,
  })).isRequired,
  languages: PropTypes.objectOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
    image: PropTypes.string,
  })).isRequired,
  region: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  onRegionChange: PropTypes.func.isRequired,
  onLanguageChange: PropTypes.func.isRequired,
  /* eslint-disable react/require-default-props */
  availableRegions: PropTypes.arrayOf(PropTypes.string),
  availableLanguages: PropTypes.arrayOf(PropTypes.string),
  overrideRegionChange: PropTypes.func,
  overrideLanguageChange: PropTypes.func,
  /* eslint-enable react/require-default-props */
};


export default withLocalization(FooterLanguage);
