import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, SubmissionError } from 'redux-form';
import { Redirect } from 'react-router-dom';

import axios from 'axios';

import {
  transformText,
  transformEmail,
  transformPhone,
  transformNumber,
  transformSelect,
  transformTerms,
} from './form';

const Form = reduxForm({
  form: 'content',
})(({
  handleSubmit,
  submitting,
  error,
  submitSucceeded,
  fields,
  submitButton,
  successUrl,
}) => {
  if (submitSucceeded) {
    return <Redirect push to={successUrl} />;
  }

  return (
    <form className="form" onSubmit={handleSubmit}>
      {fields}
      <div className="form__button">
        <button
          type="submit"
          className={`btn ${submitButton.styleType}`}
          disabled={submitting}
          style={{
              fontWeight: submitButton.fontWeight,
              fontSize: submitButton.fontSize,
            }}
        >
          {submitButton.text}
        </button>
      </div>
      {error && (
      <div className="form__form-group">
        <span className="form__form-group-error">{error}</span>
      </div>
        )}
    </form>
  );
});

const ContentForm = ({
  t,
  formType,
  fields,
  submitButton,
  successUrl,
  errorText,
  emailSubjectTemplate,
}) => {
  const formFields = fields.map(({ fieldType, ...data }) => {
    const transformers = {
      text: transformText,
      email: transformEmail,
      phone: transformPhone,
      number: transformNumber,
      select: transformSelect,
      terms: transformTerms,
    };
    return transformers[fieldType](t, data);
  });

  return (
    <Form
      validate={(values) => {
        let errors = {};
        formFields.forEach(({ validate }) => {
          errors = { ...errors, ...validate(values) };
        });
        return errors;
      }}
      onSubmit={(values) => {
        let formData = {};
        formFields.forEach(({ submit }) => {
          formData = { ...formData, ...submit(values) };
        });

        return axios
          .post(
            `https://${
              process.env.REACT_APP_API_ENV
            }-api.joyajoy.com/system/forms`,
            { formType, formData, emailSubjectTemplate },
          )
          .catch(() => {
            throw new SubmissionError({ _error: t(errorText) });
          });
      }}
      t={t}
      fields={formFields.map(({ component }) => component)}
      submitButton={{ ...submitButton, text: t(submitButton.text) }}
      successUrl={successUrl}
    />
  );
};

ContentForm.propTypes = {
  t: PropTypes.func.isRequired,
  formType: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({
    fieldType: PropTypes.oneOf([
      'text',
      'email',
      'phone',
      'number',
      'select',
      'terms',
    ]).isRequired,
  })).isRequired,
  submitButton: PropTypes.shape({
    text: PropTypes.string.isRequired,
    styleType: PropTypes.oneOf([
      'btn-primary',
      'btn-secondary',
      'btn-success',
      'btn-warning',
      'btn-danger',
      'btn-etsa-light',
      'btn-etsa-dark',
      'btn-outline-primary',
      'btn-outline-secondary',
      'btn-outline-success',
      'btn-outline-warning',
      'btn-outline-danger',
      'btn-outline-white-pink',
      'btn-outline-white-blue',
    ]),
    // eslint-disable-next-line react/require-default-props
    fontWeight: PropTypes.oneOf(['100', '300', '400', '500', '700', '900']),
    // eslint-disable-next-line react/require-default-props
    fontSize: PropTypes.string,
  }).isRequired,
  successUrl: PropTypes.string.isRequired,
  errorText: PropTypes.string.isRequired,
  // eslint-disable-next-line react/require-default-props
  emailSubjectTemplate: PropTypes.string,
};

export default ContentForm;
