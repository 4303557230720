import React from 'react';
import PropTypes from 'prop-types';
import hash from 'hash-sum';

const ContentList = ({
  t, lines, device, markerType,
}) => (
  <ul
    className={`division${device ? ` ${device}` : ''} list${
      markerType ? ` ${markerType}` : ''
    }`}
  >
    {lines.map((line) => {
      const {
        text,
        styleType = 'text',
        color,
        fontWeight,
        fontSize,
        lineHeight,
        textAlign,
      } = line;
      return text ? (
        <li
          className={styleType}
          key={hash(line)}
          style={{
            color,
            fontWeight,
            fontSize,
            lineHeight: lineHeight || (fontSize ? 1.5 : undefined),
            textAlign,
          }}
        >
          {t(text)}
        </li>
      ) : (
        <p
          className={styleType}
          key={hash(line)}
          style={{
            color,
            fontWeight,
            fontSize,
            lineHeight: lineHeight || (fontSize ? 1.5 : undefined),
            textAlign,
          }}
        >
          <br />
        </p>
      );
    })}
  </ul>
);

ContentList.propTypes = {
  t: PropTypes.func.isRequired,
  lines: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    styleType: PropTypes.oneOf([
      'header',
      'subheader',
      'text-header',
      'text',
      'big-text',
    ]),
    color: PropTypes.string,
    fontWeight: PropTypes.oneOf(['100', '300', '400', '500', '700', '900']),
    fontSize: PropTypes.string,
    lineHeight: PropTypes.string,
    textAlign: PropTypes.oneOf(['left', 'right', 'center', 'justify']),
  })).isRequired,
  device: PropTypes.oneOf([undefined, 'normal', 'mobile']),
  markerType: PropTypes.oneOf(['checkmark', 'bullet']),
};

ContentList.defaultProps = {
  device: undefined,
  markerType: 'checkmark',
};

export default ContentList;
