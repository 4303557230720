import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import TopbarMenuLink from './TopbarMenuLink';

class TopbarProfile extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      collapse: false,
    };
  }

  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  };

  render() {
    const { t } = this.props;
    return (
      <div className="topbar__profile">
        <button className="topbar__avatar" onClick={this.toggle}>
          {/* <img className="topbar__avatar-img" src={!session.account ? EmptyAva : Ava} alt="Profile" /> */}
          {/* <p className="topbar__avatar-name">Browse</p> */}
          <img className="topbar__avatar-img" src={`${process.env.PUBLIC_URL}/img/dipdip_menu.png`} alt="Menu" />
          {/* <DownIcon className="topbar__icon" /> */}
        </button>
        {this.state.collapse && <button className="topbar__back" onClick={this.toggle} />}
        <Collapse isOpen={this.state.collapse} className="topbar__menu-wrap">
          <div className="topbar__menu">
            <TopbarMenuLink title={t('footer.features')} /* icon="home" */ image="dipdip_features.png" exact path="/features" onClick={this.toggle} />
            <img className="menu__line" src={`${process.env.PUBLIC_URL}/img/dipdip_menu_line.png`} alt="Menu-line" />
            <TopbarMenuLink title={t('footer.organizers')} /* icon="home" */ image="dipdip_organizers.png" exact path="/organizers" onClick={this.toggle} />
            <img className="menu__line" src={`${process.env.PUBLIC_URL}/img/dipdip_menu_line.png`} alt="Menu-line" />
            <TopbarMenuLink title={t('footer.aboutus')} /* icon="text-align-justify" */ image="dipdip_about_us.png" exact path="/about" onClick={this.toggle} />
            <img className="menu__line" src={`${process.env.PUBLIC_URL}/img/dipdip_menu_line.png`} alt="Menu-line" />
            <TopbarMenuLink title={t('footer.contact')} /* icon="phone-handset" */ image="dipdip_contact.png" exact path="/contact" onClick={this.toggle} />
            {/* <div className="topbar__link">
              <span className="topbar__link-icon lnr lnr-user" />
              <a href="https://store.joyajoy.com/signin" target="_blank" rel="noopener noreferrer">
                <p className="topbar__link-title">{t('footer.login')}</p>
              </a>
            </div> */}
            {/* <div className="topbar__menu-divider" /> */}
            {/* <TopbarMenuLink title="Log Out" icon="exit" exact path="/logout" onClick={this.toggle} /> */}
          </div>
        </Collapse>
      </div>
    );
  }
}

export default withTranslation('common')(TopbarProfile);
