import React from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const TextCardCustom = ({
  title, titleType, text, texttype, blockColor, btnText, btnIntlLink, btnExtlLink, btnType,
}) => (
  <Row style={{ backgroundColor: blockColor ? `${blockColor}` : '#FFFFFF' }}>
    <Col sm={12} md={12} lg={12} className="normal-card">
      {title ? <h2 className={titleType}>{title}</h2> : null}
      <p className={texttype}>{text}</p>

      {btnText && btnIntlLink ?
        <Link to={btnIntlLink}>
          <button type="button" className={btnType}>
            {btnText}
          </button>
        </Link>
        : null}

      {btnText && btnExtlLink ?
        <a href={btnExtlLink} target="_blank" rel="noopener noreferrer">
          <button type="button" className={btnType}>
            {btnText}
          </button>
        </a>
        : null}
    </Col>
  </Row>
);

TextCardCustom.propTypes = {
  title: PropTypes.string,
  titleType: PropTypes.string,
  text: PropTypes.string,
  texttype: PropTypes.string,
  blockColor: PropTypes.string,
  btnText: PropTypes.string,
  btnIntlLink: PropTypes.string,
  btnExtlLink: PropTypes.string,
  btnType: PropTypes.string,
};

TextCardCustom.defaultProps = {
  title: null,
  titleType: 'header',
  text: null,
  texttype: 'text',
  blockColor: null,
  btnText: null,
  btnIntlLink: null,
  btnExtlLink: null,
  btnType: 'btn btn-primary',
};

export default TextCardCustom;
