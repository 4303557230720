import React from 'react';
import { Field } from 'redux-form';

import TextField from './TextField';

export default function transformNumber(
  t,
  { fieldName, fieldLabel, missingErrorText },
) {
  return {
    fieldName,
    validate: values =>
      (!values[fieldName] && missingErrorText
        ? { [fieldName]: missingErrorText }
        : {}),
    submit: values =>
      (values[fieldName] ? { [fieldName]: Number(values[fieldName]) } : {}),
    component: (
      <div className="form__form-group">
        <span className="form__label">{t(fieldLabel)}</span>
        <div className="form__field">
          <Field
            name={fieldName}
            component={TextField}
            t={t}
            type="number"
            min="0"
            step="1"
          />
        </div>
      </div>
    ),
  };
}
