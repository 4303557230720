import React from 'react';
// import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import Footer from '../Footer/Footer';

import {
  // BannerDiv,
  // TitleImageCard,
  // TitleDoubleImagesCard,
  TitleImageVideoCard,
  // TextCard,
  // TextCardLeft,
  // DoubleFlippyCard,
  // GetApp,
  // TitleImageCardCustom,
} from '../../shared/components/roland/RolandModules';

/* class VideoPlayer extends PureComponent {
  static propTypes = {
    // pathname: React.PropTypes.string.isRequired,
    video: PropTypes.string.isRequired,
  };
  render() {
    // const data = this.props.location.state;
    // const { video } = this.props;
    // const data = this.props;
    // const { video } = this.props;
    return (
      <Container>

        <TitleImageVideoCard
          video={this.props.video}
        />
        <Footer extra={null} />

      </Container>
    );
  }
} */

const VideoPlayer = () => (
  <Container>

    <TitleImageVideoCard
      video="home/v1.mp4"
    />
    <Footer extra={null} />

  </Container>
);

export default VideoPlayer;
