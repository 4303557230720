import React from 'react';
import i18n from 'i18next';
import { Row, Col, Container } from 'reactstrap';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import FooterLanguage from '../Layout/topbar_with_navigation/FooterLanguage';
import { config as i18nextConfig } from '../../translations';

const facebook = `${process.env.PUBLIC_URL}/img/social/Facebook.png`;
const twitter = `${process.env.PUBLIC_URL}/img/social/Twitter.png`;
const youtube = `${process.env.PUBLIC_URL}/img/social/Youtube.png`;

// initialize i18next with catalog and language to use
i18n.init(i18nextConfig);

const Footer = ({ extra, t, languageParams }) => (
  <Row>
    <footer className="landing__code subdiv_footer_Top">
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-subhead subhead footer">
              {extra || ''}
              {extra ? <br /> : ''}
              {extra ? <br /> : ''}
              {/* <Link to="/" className="router_link">Home</Link>
              &nbsp;&nbsp;&nbsp;
              <Link to="/about" className="router_link">About Us</Link>
              &nbsp;&nbsp;&nbsp;
              <Link to="/legalstatements" className="router_link">Legal Statements</Link>
              &nbsp;&nbsp;&nbsp;
              <Link to="/privacy-policy" className="router_link">Privacy Policy</Link>
              &nbsp;&nbsp;&nbsp;
              <Link to="/copyright" className="router_link">Copyright</Link>
              &nbsp;&nbsp;&nbsp;
              <Link to="/contact" className="router_link">Contact Us</Link>
              &nbsp;&nbsp;&nbsp;
              <Link to="/" className="router__footer-link">{t('footer.home')}</Link>
              &nbsp;&nbsp;&nbsp; */}
              <Link to="/features" className="router__footer-link">{t('footer.features')}</Link>
              &nbsp;&nbsp;&nbsp;
              <Link to="/organizers" className="router__footer-link">{t('footer.organizers')}</Link>
              &nbsp;&nbsp;&nbsp;
              <Link to="/about" className="router__footer-link">{t('footer.aboutus')}</Link>
              &nbsp;&nbsp;&nbsp;
              <Link to="/contact" className="router__footer-link">{t('footer.contact')}</Link>
              {/* &nbsp;&nbsp;&nbsp;
              <a href="https://store.joyajoy.com/signin" target="_blank" rel="noopener noreferrer">
                <p className="router__footer-link">{t('footer.login')}</p>
              </a> */}
              {/* <br />
              <p className="p1">{t('footer.p_text')}</p>
              <br />
              <Link to="/memberships">
                <button type="button" className="btn btn-primary-Yellow">
                  {t('footer.button_title')}
                </button>
              </Link> */}
              <br />
              <a href="https://www.facebook.com/joyAjoyOfficial/" target="_blank" rel="noopener noreferrer">
                <img className="social_footer" src={facebook} alt="facebook" />
              </a>
              <a href="https://twitter.com/joyAjoyOfficial/" target="_blank" rel="noopener noreferrer">
                <img className="social_footer" src={twitter} alt="twitter" />
              </a>
              <a href="https://www.youtube.com/channel/UCaPdW53YFr3exkqMAV0SWKQ" target="_blank" rel="noopener noreferrer">
                <img className="social_footer" src={youtube} alt="youtube" />
              </a>
              <br />
              <FooterLanguage {...languageParams} />
            </h3>
          </Col>
        </Row>
      </Container>
    </footer>
    <footer className="landing__code subdiv_footer_Bottom">
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-subhead subhead footer">
              <Link to="/legalstatements" className="router__footer-link">{t('footer.legalstatements')}</Link>
              &nbsp;&nbsp;&nbsp;
              <Link to="/privacy-policy" className="router__footer-link">{t('footer.privacy')}</Link>
              &nbsp;&nbsp;&nbsp;
              <Link to="/copyright" className="router__footer-link">{t('footer.copyright')}</Link>
              <br />
              <p className="router_footer-P">{t('footer.trademark')}</p>
              <br />
              <p className="router_footer-P">&copy; {(new Date()).getFullYear()} {t('footer.allrights')}</p>
              <br />
              { i18n.language === 'zh-Hans' ?
                <p className="router_footer-P">
                  <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery" target="_blank" rel="noopener noreferrer">
                    {t('footer.icp')}
                  </a>
                  <br />
                </p>
                : null}
              <p className="router_footer-P">{t('footer.allrights_dipdip')}</p>
            </h3>
          </Col>
        </Row>
      </Container>
    </footer>
  </Row>
);

Footer.propTypes = {
  extra: PropTypes.string,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.shape({}).isRequired,
  languageParams: PropTypes.shape({
    availableRegions: PropTypes.array,
    availableLanguages: PropTypes.array,
    overrideRegionChange: PropTypes.func,
    overrideLanguageChange: PropTypes.func,
  }),
};

Footer.defaultProps = {
  extra: null,
  languageParams: {},
};

export default (withTranslation('common')(Footer));
