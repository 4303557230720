import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';
import Flippy, { FrontSide, BackSide } from 'react-flippy';
import { Link } from 'react-router-dom';

const SingleFlippyCard = ({
  title, subtitle, left, mobileimg, backColor, btnText, btnIntlLink, btnExtlLink, sm, md, lg,
}) => (
  <Row>
    <Col sm={(12 - sm) / 2} md={(12 - md) / 2} lg={(12 - lg) / 2} />

    <Col sm={sm} md={md} lg={lg}>
      <Card className="normal-card">
        <CardBody>
          <Flippy
            flipOnHover="true" // default false
            // flipOnClick={false} // default true
            flipDirection="vertical" // horizontal or vertical
            // ref={r => this.flippy = r} // to use toggle method like this.flippy.toggle()
            // if you pass isFlipped prop component will be controlled component.
            // and other props, which will go to div
            // style={{ width: '100%', height: '100%' }} // these are optional style, it is not necessary
          >
            <FrontSide
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <img
                className="normal-image"
                src={`${process.env.PUBLIC_URL}/img/${left}`}
                alt={title}
              />

              {mobileimg ?
                <img
                  className="mobile-image"
                  src={`${process.env.PUBLIC_URL}/img/${mobileimg}`}
                  alt={title}
                /> :
                <img
                  className="mobile-image"
                  src={`${process.env.PUBLIC_URL}/img/${left}`}
                  alt={title}
                />}

              <h3 className="text-flippy">Tap to learn more</h3>
            </FrontSide>
            <BackSide
              style={{
                backgroundColor: backColor ? `${backColor}` : '#FFFFFF',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <h2 className="title">{title}</h2>
              {subtitle ? <h3 className="subtitle">{subtitle}</h3> : null}
              {btnText && btnIntlLink ?
                <Link to={btnIntlLink}>
                  <button type="button" className="btn btn-primary">
                    {btnText}
                  </button>
                </Link>
                : null}

              {btnText && btnExtlLink ?
                <a href={btnExtlLink} target="_blank" rel="noopener noreferrer">
                  <button type="button" className="btn btn-primary">
                    {btnText}
                  </button>
                </a>
                : null}
            </BackSide>
          </Flippy>
        </CardBody>
      </Card>
    </Col>

    <Col sm={(12 - sm) / 2} md={(12 - md) / 2} lg={(12 - lg) / 2} />
  </Row>
);

SingleFlippyCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  left: PropTypes.string.isRequired,
  mobileimg: PropTypes.string,
  backColor: PropTypes.string,
  btnText: PropTypes.string,
  btnIntlLink: PropTypes.string,
  btnExtlLink: PropTypes.string,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
};

SingleFlippyCard.defaultProps = {
  title: null,
  subtitle: null,
  backColor: null,
  mobileimg: null,
  btnText: null,
  btnIntlLink: null,
  btnExtlLink: null,
  sm: 12,
  md: 12,
  lg: 12,
};

export default SingleFlippyCard;
