import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import Footer from '../Footer/Footer';

import {
  // BannerDiv,
  // TextCard,
  // TitleImageCard,
  // OneToNTitleImageCard,
  TextCardLeft,
  // TitleImageCarouselCard,
  // SubBannerDivCard,
} from '../../shared/components/roland/RolandModules';

const TermsBible = () => (
  <Container>

    <Row style={{ backgroundColor: '#34b9cc' }}>
      <Col md={12} lg={12} xl={12} xs={12} className="landing__code div_aboutus">
        <h2 className="title">DipDip World Cup</h2>
      </Col>
    </Row>

    <TextCardLeft
      textArray={[
        {
          header: 'A. Progression rules for DipDip World Cup',
          lines: [
            '1. Participants play a few minutes of games daily in the App. According to their Weekly Challenge performance, Gold, Silver and Bronze Awards will be given accordingly. (About 5% of the participants will receive Gold Awards, about 15% will receive Silver Awards and about 25% will receive Bronze Awards.)',
            '2. Each participant has to collect at least 3 e-tickets from the Weekly Challenges to participate in the Monthly Challenge once. Participants who get Bronze Awards or above in each Weekly Challenge will receive at least 1 e-ticket. They will be informed of the date and details of the Monthly Challenge through notification in the app. According to their Monthly Challenge performance, Gold, Silver and Bronze Awards will be given accordingly. (About 5% of the participants will receive Gold Awards, about 15% will receive Silver Awards and about 25% will receive Bronze Awards.)',
            '3. Each participant has to collect at least 3 e-tickets from the Monthly Challenges to participate in the Quarterly Challenge once. Participants who get Bronze Awards or above in each Monthly Challenge will receive at least 1 e-ticket. They will be informed of the date and details of the Quarterly Challenge through notification in the app. According to their Quarterly Challenge performance, Gold, Silver and Bronze Awards will be given accordingly. (About 5% of the participants will receive Gold Awards, about 15% will receive Silver Awards and about 25% will receive Bronze Awards.)',
            '4. Each participant has to collect at least 3 e-tickets from the Quarterly Challenges to participate in the Yearly Challenge once. Participants who get Bronze Awards or above in each Quarterly Challenge will receive at least 1 e-ticket. They will be informed of the date and details of the Yearly Challenge through notification in the app. According to their Yearly Challenge performance, Champion, 1st Runner-up and 2nd Runner-up will be given accordingly.',
            '5. The top three participants in each category in the Yearly Challenge from each country or region will be invited to participate in the onsite Yearly World Cup to compete for their own country or region. They will be informed of the date and details of the Yearly World Cup later in the app.',
          ],
        },
        {
          header: 'B. Terms and Conditions',
          lines: [
            '1. There are 12 languages provided in the DipDip World Cup Bible and each participant can choose to participate in more than 1 language.',
            '2. The parent / guardian / participant hereby authorizes the Organizer to show the participant’s name, portrait, video and awards on websites or in other media.',
            '3. Each participant shall comply with all specific rules and regulations of the Competition. Failure to comply with these may result in disqualification from the Competitions.',
            '4. The Organizer reserves the right to allow or not allow any participants to the Competitions.',
            '5. The Organizer reserves the right of final interpretation for any disputes.',
            '6. The Organizer reserves the right to change any rules, procedures, prizes or dates.',
            '7. Each participant can compete in one level only in the same Competition.',
          ],
        },
      ]}
    />
    <Footer extra={null} />

  </Container>
);

export default TermsBible;
