import React from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';

import Footer from '../Footer/Footer';

const FormSuccess = ({ t }) => (
  <Container>
    <Row style={{ backgroundColor: '#FFFFFF' }}>
      <Col />

      <Col sm={12} md={12} lg={12}>
        <Card className="normal-card">
          <CardBody style={{ backgroundColor: '#FFFFFF' }}>
            <h2 className="title">{t('application.success')}</h2>
            <p className="text">{t('application.thank_you')}</p>
          </CardBody>
        </Card>
      </Col>

      <Col />
    </Row>
    <Footer extra={null} />
  </Container>
);

FormSuccess.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(FormSuccess);
