import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';
// import Flippy, { FrontSide, BackSide } from 'react-flippy';
// import { Link } from 'react-router-dom';
import SingleFlippyCard from './SingleFlippyCard';

const DoubleFlippyCard = ({
  titleleft, subtitleleft, titleright, subtitleright, leftimg, rightimg, mobileleftimg, mobilerightimg, backColorleft, backColorright, btnTextleft, btnIntlLinkleft, btnExtlLinkleft, btnTextright, btnIntlLinkright, btnExtlLinkright,
}) => (
  <Row>
    <Col md={6} sm={12} xs={12}>
      <SingleFlippyCard
        // className="left-image"
        title={titleleft}
        subtitle={subtitleleft}
        left={leftimg}
        mobileimg={mobileleftimg}
        backColor={backColorleft}
        btnText={btnTextleft}
        btnIntlLink={btnIntlLinkleft}
        btnExtlLink={btnExtlLinkleft}
      />
    </Col>
    <Col md={6} sm={12} xs={12}>
      <SingleFlippyCard
        // className="right-image"
        title={titleright}
        subtitle={subtitleright}
        left={rightimg}
        mobileimg={mobilerightimg}
        backColor={backColorright}
        btnText={btnTextright}
        btnIntlLink={btnIntlLinkright}
        btnExtlLink={btnExtlLinkright}
      />
    </Col>
    <Col md={6} sm={12} xs={12}>
      <Card>
        <CardBody>
          <SingleFlippyCard
            // className="left-image"
            title={titleleft}
            subtitle={subtitleleft}
            left={leftimg}
            mobileimg={mobileleftimg}
            backColor={backColorleft}
            btnText={btnTextleft}
            btnIntlLink={btnIntlLinkleft}
            btnExtlLink={btnExtlLinkleft}
          />
        </CardBody>
      </Card>
    </Col>
    <Col md={6} sm={12} xs={12}>
      <Card>
        <CardBody>
          <SingleFlippyCard
            // className="right-image"
            title={titleright}
            subtitle={subtitleright}
            left={rightimg}
            mobileimg={mobilerightimg}
            backColor={backColorright}
            btnText={btnTextright}
            btnIntlLink={btnIntlLinkright}
            btnExtlLink={btnExtlLinkright}
          />
        </CardBody>
      </Card>
    </Col>

  </Row>
);

DoubleFlippyCard.propTypes = {
  titleleft: PropTypes.string,
  subtitleleft: PropTypes.string,
  leftimg: PropTypes.string.isRequired,
  rightimg: PropTypes.string.isRequired,
  mobilerightimg: PropTypes.string,
  mobileleftimg: PropTypes.string,
  titleright: PropTypes.string,
  subtitleright: PropTypes.string,
  backColorleft: PropTypes.string,
  backColorright: PropTypes.string,
  btnTextleft: PropTypes.string,
  btnIntlLinkleft: PropTypes.string,
  btnExtlLinkleft: PropTypes.string,
  btnTextright: PropTypes.string,
  btnIntlLinkright: PropTypes.string,
  btnExtlLinkright: PropTypes.string,
};

DoubleFlippyCard.defaultProps = {
  titleleft: null,
  subtitleleft: null,
  titleright: null,
  subtitleright: null,
  backColorleft: null,
  backColorright: null,
  mobileleftimg: null,
  mobilerightimg: null,
  btnTextright: null,
  btnIntlLinkright: null,
  btnExtlLinkright: null,
  btnTextleft: null,
  btnIntlLinkleft: null,
  btnExtlLinkleft: null,
};

export default DoubleFlippyCard;
