import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
// import TopbarNavDashboards from './TopbarNavDashboards';
// import TopbarNavUIElements from './TopbarNavUIElements';
// import TopbarNavOtherPages from './TopbarNavOtherPages';

const TopbarNav = ({ t }) => (
  /* <nav className="topbar__nav">
    <Link className="topbar__nav-link" to="/home">Home</Link>
    <Link className="topbar__nav-link" to="/about">About Us</Link>
    <Link className="topbar__nav-link" to="/contact">Contact Us</Link>
  </nav> */
  <nav className="topbar__menu-nav">
    <Link to="/features">
      <button>
        {t('footer.features')}
      </button>
    </Link>
    <Link to="/organizers">
      <button>
        {t('footer.organizers')}
      </button>
    </Link>
    <Link to="/about">
      <button>
        {t('footer.aboutus')}
      </button>
    </Link>
    <Link to="/contact">
      <button>
        {t('footer.contact')}
      </button>
    </Link>
    {/* <a href="https://store.joyajoy.com/signin" target="_blank" rel="noopener noreferrer">
      <button>
        {t('footer.login')}
      </button>
    </a> */}
  </nav>
);

TopbarNav.propTypes = {
  t: PropTypes.func.isRequired,
};

export default (withTranslation('common')(TopbarNav));
