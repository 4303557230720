import React from 'react';
import { withTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import { Container } from 'reactstrap';
import Footer from '../Footer/Footer';

import {
  BannerDiv,
  TitleImageCard,
  // TitleImageSplitCard,
  // TitleImageCarouselCard,
  // TitleDoubleImagesCard,
  // TitleImageVideoCard,
  // TextCard,
  // TextCardCustom,
  // TitleImageVideoCard,
  // TextCardLeft,
  // DoubleFlippyCard,
  // GetApp,
  // TitleImageCardCustom,
} from '../../shared/components/roland/RolandModules';

const MemberShips = ({ t }) => (
  <Container>

    {/* <DoubleFlippyCard
      titleleft="DipDip World Cup Bible"
      subtitleleft="12 Languages for Selection"
      titleright="DipDip World Cup Bible"
      subtitleright="12 Languages for Selection"
      leftimg="home/dt2.png"
      rightimg="home/dt2.png"
      backColorleft="#F5F5F5"
      backColorright="#F5F5F5"
      btnTextleft="Join Now!"
      btnExtlLinkleft="https://joyajoy.com"
      btnTextright="Join Now!"
      btnExtlLinkright="https://joyajoy.com"
    /> */}

    <BannerDiv
      image={t('memberships.first.image')}
      mobileImage={t('memberships.first.mobileimage')}
    />
    <TitleImageCard
      title={t('memberships.partner.title')}
      // titleType="white-title"
      // subtitle={t('memberships.subscriber_member.header')}
      // subtitleType="white-subtitle"
      textArray={[
        {
          lines: [
            t('memberships.partner.textitems.texta'),
            t('memberships.partner.textitems.textb'),
          ],
        },
      ]}
      // texttype="white-text"
      image={t('memberships.partner.image')}
      mobileImage={t('memberships.partner.mobileimage')}
      blockColor="#F5F5F5"
      btnText={t('memberships.partner.btntxt')}
      btnExtlLink="mailto:info@joyAjoy.com?subject=Be our partner"
      btntype="btn btn-primary-Yellow"
    />

    <Footer extra={null} />

  </Container>
);

MemberShips.propTypes = {
  t: PropTypes.func.isRequired,
};

export default (withTranslation('common')(MemberShips));
