import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';

const TitleImageSplitCard = ({
  title, titleType, subtitle, image, mobileImage, mobileImageArray, blockColor, btnText, btnIntlLink, btnExtlLink, btntype, sm, md, lg,
}) => (
  <Row style={{ backgroundColor: blockColor ? `${blockColor}` : '#FFFFFF' }}>
    <Col sm={(12 - sm) / 2} md={(12 - md) / 2} lg={(12 - lg) / 2} />

    <Col sm={sm} md={md} lg={lg}>
      <Card className="normal-card">
        <CardBody style={{ backgroundColor: blockColor ? `${blockColor}` : '#FFFFFF' }}>
          {title ? <h2 className={titleType}>{title}</h2> : null}
          {subtitle ? <h3 className="subtitle">{subtitle}</h3> : null}

          <img
            className="normal-image"
            src={`${process.env.PUBLIC_URL}/img/${image}`}
            alt={title}
          />

          {mobileImage ?
            <img
              className="mobile-image"
              src={`${process.env.PUBLIC_URL}/img/${mobileImage}`}
              alt={title}
            /> :
            null}

          {mobileImageArray ?
            mobileImageArray.map(item => (
              <div>
                <img className="mobile-div" src={`${process.env.PUBLIC_URL}/img/${item.image}`} alt={item.title ? item.title : 'Slide'} />
              </div>
            ))
            : null}

          {btnText && btnIntlLink ?
            <Link to={btnIntlLink}>
              <button type="button" className={btntype}>
                {btnText}
              </button>
            </Link>
            : null}

          {btnText && btnExtlLink ?
            <a href={btnExtlLink} target="_blank" rel="noopener noreferrer">
              <button type="button" className={btntype}>
                {btnText}
              </button>
            </a>
            : null}

        </CardBody>
      </Card>
    </Col>

    <Col sm={(12 - sm) / 2} md={(12 - md) / 2} lg={(12 - lg) / 2} />
  </Row>
);

TitleImageSplitCard.propTypes = {
  title: PropTypes.string,
  titleType: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.string.isRequired,
  mobileImage: PropTypes.string,
  mobileImageArray: PropTypes.arrayOf(PropTypes.shape({
    image: PropTypes.string.isRequired,
  })),
  blockColor: PropTypes.string,
  btnText: PropTypes.string,
  btnIntlLink: PropTypes.string,
  btnExtlLink: PropTypes.string,
  btntype: PropTypes.string,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
};

TitleImageSplitCard.defaultProps = {
  title: null,
  titleType: 'title',
  subtitle: null,
  mobileImage: null,
  mobileImageArray: null,
  blockColor: null,
  btnText: null,
  btnIntlLink: null,
  btnExtlLink: null,
  btntype: 'btn btn-outline-primary',
  sm: 12,
  md: 12,
  lg: 12,
};

export default TitleImageSplitCard;
