import React from 'react';
import { Field } from 'redux-form';
import { validate as isEmail } from 'isemail';

import TextField from './TextField';

export default function transformEmail(
  t,
  {
    fieldName, fieldLabel, missingErrorText, invalidErrorText,
  },
) {
  return {
    validate: (values) => {
      if (!values[fieldName] && missingErrorText) {
        return { [fieldName]: missingErrorText };
      }
      if (values[fieldName] && !isEmail(values[fieldName])) {
        // !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test
        return { [fieldName]: invalidErrorText };
      }
      return {};
    },
    submit: values => ({ [fieldName]: values[fieldName] }),
    component: (
      <div className="form__form-group">
        <span className="form__label">{t(fieldLabel)}</span>
        <div className="form__field">
          <Field
            name={fieldName}
            component={TextField}
            t={t}
            type="email"
            autoComplete="email"
          />
        </div>
      </div>
    ),
  };
}
