import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';
import Carousel from '../../components/carousel/CarouselSingle';

const TitleImageCarouselCard = ({
  title, titleType, subtitle, subtitleType, text, texttype, image, mobileImage, blockColor, carouselItems, btnText, btnIntlLink, btnExtlLink, btntype, sm, md, lg,
}) => (
  <Row style={{ backgroundColor: blockColor ? `${blockColor}` : '#FFFFFF' }}>
    <Col sm={(12 - sm) / 2} md={(12 - md) / 2} lg={(12 - lg) / 2} />

    <Col sm={sm} md={md} lg={lg}>
      <Card className="normal-card">
        <CardBody style={{ backgroundColor: blockColor ? `${blockColor}` : '#FFFFFF' }}>
          <h2 className={titleType}>{title}</h2>
          {subtitle ? <h3 className={subtitleType}>{subtitle}</h3> : null}
          <p className={texttype}>{text}</p>

          {image ?
            <img
              className="normal-image"
              src={`${process.env.PUBLIC_URL}/img/${image}`}
              alt={title}
            />
            : null}

          {mobileImage ?
            <img
              className="mobile-image"
              src={`${process.env.PUBLIC_URL}/img/${mobileImage}`}
              alt={title}
            />
            : null}

          <Carousel>
            {carouselItems.map(item => (
              <div>
                <img className="normal-image" src={`${process.env.PUBLIC_URL}/img/${item.image}`} alt={item.title ? item.title : 'Slide'} />
                <img className="mobile-image" src={`${process.env.PUBLIC_URL}/img/${item.mobileImage}`} alt={item.title ? item.title : 'Slide'} />
                {/* {item.title || item.text ?
                  <div className="slick-slider__caption">
                    {item.title ? <p className="slick-slider__caption-title">{item.title}</p> : null}
                    {item.text ? <p className="slick-slider__caption-description">{item.text}</p> : null}
                  </div>
                  : null} */}
              </div>
            ))}
          </Carousel>

          {btnText && btnIntlLink ?
            <Link to={btnIntlLink}>
              <button type="button" className={btntype}>
                {btnText}
              </button>
            </Link>
            : null}

          {btnText && btnExtlLink ?
            <a href={btnExtlLink} target="_blank" rel="noopener noreferrer">
              <button type="button" className={btntype}>
                {btnText}
              </button>
            </a>
            : null}

        </CardBody>
      </Card>
    </Col>

    <Col sm={(12 - sm) / 2} md={(12 - md) / 2} lg={(12 - lg) / 2} />
  </Row>
);

TitleImageCarouselCard.propTypes = {
  title: PropTypes.string.isRequired,
  titleType: PropTypes.string,
  subtitle: PropTypes.string,
  subtitleType: PropTypes.string,
  text: PropTypes.string,
  texttype: PropTypes.string,
  image: PropTypes.string,
  mobileImage: PropTypes.string,
  blockColor: PropTypes.string,
  carouselItems: PropTypes.arrayOf(PropTypes.shape({
    image: PropTypes.string.isRequired,
    mobileImage: PropTypes.string.isRequired,
    title: PropTypes.string,
    text: PropTypes.string,
  })).isRequired,
  btnText: PropTypes.string,
  btnIntlLink: PropTypes.string,
  btnExtlLink: PropTypes.string,
  btntype: PropTypes.string,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
};

TitleImageCarouselCard.defaultProps = {
  titleType: 'title',
  subtitle: null,
  subtitleType: 'subtitle',
  text: null,
  texttype: 'text',
  image: null,
  mobileImage: null,
  blockColor: null,
  btnText: null,
  btnIntlLink: null,
  btnExtlLink: null,
  btntype: 'btn btn-outline-primary',
  sm: 12,
  md: 12,
  lg: 12,
};

export default TitleImageCarouselCard;
