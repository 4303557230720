import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter, Redirect } from 'react-router-dom';
import axios from 'axios';
import Ajv from 'ajv';
import { stripComments } from 'jsonc-parser';

import {
  fetchPageManifest,
  fetchPageManifestSuccess,
  fetchPageManifestFailure,
} from '../../redux/actions/page/pageActions';

import { Page, manifestSchema } from '../../shared/components/page';
import Footer from '../Footer/Footer';

const DynamicPage = ({
  match, dispatch, i18n, pageId, state, manifest,
}) => {
  const [t, setT] = useState(() => i18n.getFixedT(null, pageId));
  useEffect(() => {
    if (match.params.pageId !== pageId) {
      dispatch(fetchPageManifest(match.params.pageId));
      axios
        .get(
          `${process.env.PUBLIC_URL}/pages/${match.params.pageId}/manifest.jsonc`,
          {
            transformResponse: [
              (data) => {
                try {
                  return JSON.parse(stripComments(data));
                } catch (e) {
                  return null;
                }
              },
            ],
          },
        )
        .then((res) => {
          const ajv = new Ajv();
          if (!ajv.validate(manifestSchema, res.data)) {
            console.error(ajv.errors);
            throw new Ajv.ValidationError(ajv.errors);
          }

          dispatch(fetchPageManifestSuccess(match.params.pageId, res.data));
        })
        .catch((err) => {
          dispatch(fetchPageManifestFailure(match.params.pageId, err));
        });
    }
  }, [match, pageId]);

  useEffect(() => {
    if (manifest) {
      Object.entries(manifest.translations).forEach(([lng, resources]) => {
        if (!i18n.hasResourceBundle(lng, pageId)) {
          i18n.addResourceBundle(lng, pageId, resources);
        }
        setT(() => i18n.getFixedT(null, pageId));
      });
    }
  }, [pageId, manifest]);

  if (match.params.pageId === pageId && state === 'error') {
    return <Redirect to="/" />;
  }

  if (state !== 'success') {
    return <div />;
  }

  return (
    <Page
      path={`${process.env.PUBLIC_URL}/pages/${pageId}`}
      t={t}
      cards={manifest.cards}
      footer={<Footer />}
    />
  );
};

DynamicPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ pageId: PropTypes.string.isRequired }).isRequired,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  i18n: PropTypes.shape({
    addResourceBundle: PropTypes.func.isRequired,
    getFixedT: PropTypes.func.isRequired,
  }).isRequired,
  pageId: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  manifest: PropTypes.shape({
    cards: PropTypes.array,
    translations: PropTypes.object,
  }).isRequired,
};

export default compose(
  withRouter,
  connect(({ page }) => page),
  withTranslation(),
)(DynamicPage);
