import React from 'react';
import { Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

// const LOGO = `${process.env.PUBLIC_URL}/img/app/AppIcon.png`;
// const APP_STORE = `${process.env.PUBLIC_URL}/img/app/app_store.png`;
// const PLAY_STORE = `${process.env.PUBLIC_URL}/img/app/play_store.png`;
// const LOGO_MB = `${process.env.PUBLIC_URL}/img/app/AppIcon_mb.png`;
// const APP_STORE_MB = `${process.env.PUBLIC_URL}/img/app/app_store_mb.png`;
// const PLAY_STORE_MB = `${process.env.PUBLIC_URL}/img/app/play_store_mb.png`;

const GetApp = ({ blockColor, t }) => (
  /* <Row style={{ backgroundColor: blockColor ? `${blockColor}` : '#FFFFFF' }}>
    <div className="landing_div">
      <h2 className="app_get_title">{t('home.download.title')}</h2>
      <img className="landing_div log_img" src={`${process.env.PUBLIC_URL}/img/${t('home.download.imageA')}`} alt="joyajoy" />
      <img className="log_mb_img" src={`${process.env.PUBLIC_URL}/img/${t('home.download.mobileimageA')}`} alt="joyajoy" />
      <div className="downloads">
        <a href="https://itunes.apple.com/app/dipdip/id1099703101?mt=8" target="_blank" rel="noopener noreferrer">
          <img className="app_store" src={`${process.env.PUBLIC_URL}/img/${t('home.download.imageB')}`} alt="AppStore" />
          <img className="app_mb_store" src={`${process.env.PUBLIC_URL}/img/${t('home.download.mobileimageB')}`} alt="AppStore" />
        </a>
        &nbsp;
        <a href="https://play.google.com/store/apps/details?id=com.dipdip" target="_blank" rel="noopener noreferrer">
          <img className="play_store" src={`${process.env.PUBLIC_URL}/img/${t('home.download.imageC')}`} alt="PlayStore" />
          <img className="play_mb_store" src={`${process.env.PUBLIC_URL}/img/${t('home.download.mobileimageC')}`} alt="PlayStore" />
        </a>
      </div>
    </div>
  </Row> */
  <Row style={{ backgroundColor: blockColor ? `${blockColor}` : '#FFFFFF' }}>
    <div className="landing_div">
      <h2 className="app_get_title">{t('home.download.title')}</h2>
      <img src={`${process.env.PUBLIC_URL}/img/${t('home.download.imageA')}`} alt="joyajoy" />
      <div className="downloads">
        <a href="https://itunes.apple.com/app/dipdip/id1099703101?mt=8" target="_blank" rel="noopener noreferrer">
          <img src={`${process.env.PUBLIC_URL}/img/${t('home.download.imageB')}`} alt="AppStore" />
        </a>
        &nbsp;
        <a href="https://play.google.com/store/apps/details?id=com.dipdip" target="_blank" rel="noopener noreferrer">
          <img src={`${process.env.PUBLIC_URL}/img/${t('home.download.imageC')}`} alt="PlayStore" />
        </a>
      </div>
    </div>
  </Row>
);

GetApp.propTypes = {
  blockColor: PropTypes.string,
  t: PropTypes.func.isRequired,
};

GetApp.defaultProps = {
  blockColor: null,
};

export default (withTranslation('common')(GetApp));
