import React from 'react';
import { Field } from 'redux-form';

import TextField from './TextField';
import SelectField from './SelectField';

export default function transformPhone(
  t,
  {
    fieldName,
    fieldLabel,
    missingErrorText,
    countryCodes = ['+852', '+853', '+86'],
  },
) {
  return {
    fieldName,
    validate: values =>
      ((!values[`${fieldName}-country-code`] ||
        !values[`${fieldName}-number`]) &&
      missingErrorText
        ? { [`${fieldName}-number`]: missingErrorText }
        : {}),
    submit: values =>
      (values[`${fieldName}-country-code`] && values[`${fieldName}-number`]
        ? {
          [fieldName]: `${values[`${fieldName}-country-code`]} ${
            values[`${fieldName}-number`]
          }`,
        }
        : {}),
    component: (
      <div className="form__form-group">
        <span className="form__label">{t(fieldLabel)}</span>
        <div className="form__field">
          <div className="form__phone-country-code">
            <Field
              name={`${fieldName}-country-code`}
              component={SelectField}
              options={countryCodes.map(code => ({
                value: code,
                label: code,
              }))}
            />
          </div>
          <div className="form__phone-number">
            <Field
              name={`${fieldName}-number`}
              component={TextField}
              t={t}
              type="number"
              min="0"
              step="1"
            />
          </div>
        </div>
      </div>
    ),
  };
}
