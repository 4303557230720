import React from 'react';
import { Container } from 'reactstrap';
import { PropTypes } from 'prop-types';
import hash from 'hash-sum';

import Banner from './Banner';
import ContentCard from './ContentCard';

const components = {
  banner: Banner,
  content: ContentCard,
};

const Page = ({
  path, t, cards, footer,
}) => (
  <Container>
    {cards.map(({ type, ...data }) => {
      const Component = components[type];
      return Component ? (
        <Component key={hash(data)} path={path} t={t} {...data} />
      ) : null;
    })}
    {footer}
  </Container>
);

Page.propTypes = {
  path: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  cards: PropTypes.arrayOf(PropTypes.shape({ type: PropTypes.oneOf(['banner', 'content']) })).isRequired,
  footer: PropTypes.element.isRequired,
};

export default Page;
