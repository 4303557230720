import React from 'react';
import { PropTypes } from 'prop-types';

const TextField = ({
  input,
  meta: { touched, error },
  t,
  type,
  placeholder,
}) => (
  <div className="form__input-wrap">
    <input {...input} placeholder={placeholder} type={type} />
    {touched && error && <span className="form__error">{t(error)}</span>}
  </div>
);

TextField.propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  t: PropTypes.func.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
};

TextField.defaultProps = {
  type: 'text',
  placeholder: '',
};

export default TextField;
