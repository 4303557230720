import React from 'react';
import { Row } from 'reactstrap';
import PropTypes from 'prop-types';

const Banner = ({
  path, t, normal, mobile, text,
}) => (
  <Row>
    <div className="page-banner">
      <img className="normal" src={`${path}/${t(normal)}`} alt={t(text)} />
      <img
        className="mobile"
        src={`${path}/${mobile ? t(mobile) : t(normal)}`}
        alt={t(text)}
      />
    </div>
  </Row>
);

Banner.propTypes = {
  path: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  normal: PropTypes.string.isRequired,
  mobile: PropTypes.string,
  text: PropTypes.string,
};

Banner.defaultProps = {
  mobile: '',
  text: 'Banner',
};

export default Banner;
