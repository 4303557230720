import React from 'react';
import PropTypes from 'prop-types';

import Carousel from '../../components/carousel/CarouselSingle';
import ContentImage from './ContentImage';

const ContentCarousel = ({ path, t, images }) => (
  <Carousel>
    {images.map(({ normal, mobile, text }) => (
      <div key={`${normal}-${mobile}-${text}`}>
        <ContentImage
          path={path}
          t={t}
          normal={t(normal)}
          mobile={t(mobile)}
          text={t(text)}
          styleType="image"
        />
      </div>
    ))}
  </Carousel>
);

ContentCarousel.propTypes = {
  path: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  images: PropTypes.arrayOf(PropTypes.shape({
    normal: PropTypes.string.isRequired,
    mobile: PropTypes.string,
    text: PropTypes.string,
  })).isRequired,
};

export default ContentCarousel;
