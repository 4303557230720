import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';

const ContentVideo = ({
  path, t, video, thumbnail,
}) => (
  <div className="division">
    <div className="react-player-wrapper">
      <ReactPlayer
        className="react-player"
        url={`${path}/${t(video)}`}
        width="100%"
        height="100%"
        controls
        playing
        light={thumbnail ? `${path}/${t(thumbnail)}` : false}
        playIcon={
          <img
            className="react-player__preview-play"
            src={`${process.env.PUBLIC_URL}/img/page/video-play.png`}
            alt="play"
          />
        }
      />
    </div>
  </div>
);

ContentVideo.propTypes = {
  path: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  video: PropTypes.string.isRequired,
  thumbnail: PropTypes.string,
};

ContentVideo.defaultProps = {
  thumbnail: '',
};

export default ContentVideo;
