import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import RightIcon from 'mdi-react/ChevronRightIcon';

const ContentLink = ({
  t,
  text,
  url,
  isExternal,
  styleType = 'text',
  color,
  fontWeight,
  fontSize,
  lineHeight,
  textAlign,
  device,
}) => (
  <div className={device ? `division ${device}` : 'division'}>
    {isExternal ? (
      <a href={url} target="_blank" rel="noopener noreferrer">
        <p
          className={styleType}
          style={{
            color,
            fontWeight,
            fontSize,
            lineHeight: lineHeight || (fontSize ? 1.5 : undefined),
            textAlign,
            textDecoration: 'underline',
          }}
        >
          {text ? t(text) : <br />}
          <RightIcon />
        </p>
      </a>
    ) : (
      <Link to={url}>
        <p
          className={styleType}
          style={{
            color,
            fontWeight,
            fontSize,
            lineHeight: lineHeight || (fontSize ? 1.5 : undefined),
            textAlign,
            textDecoration: 'underline',
          }}
        >
          {text ? t(text) : <br />}
          <RightIcon />
        </p>
      </Link>
    )}
  </div>
);

ContentLink.propTypes = {
  t: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  isExternal: PropTypes.bool,
  styleType: PropTypes.oneOf([
    'header',
    'subheader',
    'text-header',
    'text',
    'big-text',
  ]),
  /* eslint-disable react/require-default-props */
  color: PropTypes.string,
  fontWeight: PropTypes.oneOf(['100', '300', '400', '500', '700', '900']),
  fontSize: PropTypes.string,
  lineHeight: PropTypes.string,
  textAlign: PropTypes.oneOf(['left', 'right', 'center', 'justify']),
  /* eslint-enable react/require-default-props */
  device: PropTypes.oneOf([undefined, 'normal', 'mobile']),
};

ContentLink.defaultProps = {
  isExternal: false,
  styleType: 'text',
  device: undefined,
};

export default ContentLink;
