import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';

const TitleImageCard = ({
  title, titleType, subtitle, subtitleType, text, texttype, textArray, image, mobileImage, blockColor, btnText, btnIntlLink, btnExtlLink, btntype, sm, md, lg,
}) => (
  <Row style={{ backgroundColor: blockColor ? `${blockColor}` : '#FFFFFF' }}>
    <Col sm={(12 - sm) / 2} md={(12 - md) / 2} lg={(12 - lg) / 2} />

    <Col sm={sm} md={md} lg={lg}>
      <Card className="normal-card">
        <CardBody style={{ backgroundColor: blockColor ? `${blockColor}` : '#FFFFFF' }}>
          {title ? <h2 className={titleType}>{title}</h2> : null}
          {subtitle ? <h3 className={subtitleType}>{subtitle}</h3> : null}
          <p className={texttype}>{text}</p>
          {textArray ?
            textArray.map(item => (
              <div key={item.header}>
                {item.lines.map(line => (
                  <p className={texttype} key={line}>{line}</p>
                ))}
                <br />
              </div>
            ))
            : null}

          <img
            className="normal-image"
            src={`${process.env.PUBLIC_URL}/img/${image}`}
            alt={title}
          />

          {mobileImage ?
            <img
              className="mobile-image"
              src={`${process.env.PUBLIC_URL}/img/${mobileImage}`}
              alt={title}
            /> :
            <img
              className="mobile-image"
              src={`${process.env.PUBLIC_URL}/img/${image}`}
              alt={title}
            />}

          {btnText && btnIntlLink ?
            <Link to={btnIntlLink}>
              <button type="button" className={btntype}>
                {btnText}
              </button>
            </Link>
            : null}

          {btnText && btnExtlLink ?
            <a href={btnExtlLink} target="_blank" rel="noopener noreferrer">
              <button type="button" className={btntype}>
                {btnText}
              </button>
            </a>
            : null}

        </CardBody>
      </Card>
    </Col>

    <Col sm={(12 - sm) / 2} md={(12 - md) / 2} lg={(12 - lg) / 2} />
  </Row>
);

TitleImageCard.propTypes = {
  title: PropTypes.string,
  titleType: PropTypes.string,
  subtitle: PropTypes.string,
  subtitleType: PropTypes.string,
  text: PropTypes.string,
  texttype: PropTypes.string,
  textArray: PropTypes.arrayOf(PropTypes.shape({
    lines: PropTypes.arrayOf(PropTypes.string).isRequired,
  })),
  image: PropTypes.string.isRequired,
  mobileImage: PropTypes.string,
  blockColor: PropTypes.string,
  btnText: PropTypes.string,
  btnIntlLink: PropTypes.string,
  btnExtlLink: PropTypes.string,
  btntype: PropTypes.string,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
};

TitleImageCard.defaultProps = {
  title: null,
  titleType: 'title',
  subtitle: null,
  subtitleType: 'subtitle',
  text: null,
  texttype: 'text',
  textArray: null,
  mobileImage: null,
  blockColor: null,
  btnText: null,
  btnIntlLink: null,
  btnExtlLink: null,
  btntype: 'btn btn-outline-primary',
  sm: 12,
  md: 12,
  lg: 12,
};

export default TitleImageCard;
