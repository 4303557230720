import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const TextCard = ({
  title, titleType, headertype, texttype, blockColor, textArray, btnText, btnIntlLink, btnExtlLink, btnType, sm, md, lg,
}) => (
  <Row style={{ backgroundColor: blockColor ? `${blockColor}` : '#FFFFFF' }}>
    <Col sm={(12 - sm) / 2} md={(12 - md) / 2} lg={(12 - lg) / 2} />

    <Col sm={sm} md={md} lg={lg}>
      <Card className="normal-card">
        <CardBody style={{ backgroundColor: blockColor ? `${blockColor}` : '#FFFFFF' }}>
          {title ? <h2 className={titleType}>{title}</h2> : null}
          {textArray.map(item => (
            <div key={item.header}>

              <p className={headertype}>{item.header}</p>

              {item.lines.map(line => (
                <p className={texttype} key={line}>{line}</p>
              ))}

              <br />

            </div>
          ))}

          {btnText && btnIntlLink ?
            <Link to={btnIntlLink}>
              <button type="button" className={btnType}>
                {btnText}
              </button>
            </Link>
            : null}

          {btnText && btnExtlLink ?
            <a href={btnExtlLink} target="_blank" rel="noopener noreferrer">
              <button type="button" className={btnType}>
                {btnText}
              </button>
            </a>
            : null}

        </CardBody>
      </Card>
    </Col>

    <Col sm={(12 - sm) / 2} md={(12 - md) / 2} lg={(12 - lg) / 2} />
  </Row>
);

TextCard.propTypes = {
  textArray: PropTypes.arrayOf(PropTypes.shape({
    header: PropTypes.string.isRequired,
    lines: PropTypes.arrayOf(PropTypes.string).isRequired,
  })).isRequired,
  title: PropTypes.string,
  titleType: PropTypes.string,
  headertype: PropTypes.string,
  texttype: PropTypes.string,
  blockColor: PropTypes.string,
  btnText: PropTypes.string,
  btnIntlLink: PropTypes.string,
  btnExtlLink: PropTypes.string,
  btnType: PropTypes.string,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
};

TextCard.defaultProps = {
  title: null,
  titleType: 'title',
  headertype: 'header',
  texttype: 'text',
  blockColor: null,
  btnText: null,
  btnIntlLink: null,
  btnExtlLink: null,
  btnType: 'btn btn-primary',
  sm: 12,
  md: 12,
  lg: 12,
};

export default TextCard;
