import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';

const MultipleImagesCard = ({
  imageArray, blockColor, sm, md, lg,
}) => (
  <Row style={{ backgroundColor: blockColor ? `${blockColor}` : '#FFFFFF' }}>
    <Col sm={(12 - sm) / 2} md={(12 - md) / 2} lg={(12 - lg) / 2} />

    <Col sm={sm} md={md} lg={lg}>
      <Card className="normal-card">
        <CardBody style={{ backgroundColor: blockColor ? `${blockColor}` : '#FFFFFF' }}>

          {imageArray.map(item => (
            <div key={item.image}>

              <img
                className="normal-image"
                src={`${process.env.PUBLIC_URL}/img/${item.image}`}
                alt={item.text || ''}
              />

              {item.mobileImage ?
                <img
                  className="mobile-image"
                  src={`${process.env.PUBLIC_URL}/img/${item.mobileImage}`}
                  alt={item.text || ''}
                /> :
                <img
                  className="mobile-image"
                  src={`${process.env.PUBLIC_URL}/img/${item.image}`}
                  alt={item.text || ''}
                />}

            </div>
            ))}

        </CardBody>
      </Card>
    </Col>

    <Col sm={(12 - sm) / 2} md={(12 - md) / 2} lg={(12 - lg) / 2} />
  </Row>
);

MultipleImagesCard.propTypes = {
  imageArray: PropTypes.arrayOf(PropTypes.shape({
    image: PropTypes.string.isRequired,
    mobileImage: PropTypes.string,
    text: PropTypes.string,
  })).isRequired,
  blockColor: PropTypes.string,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
};

MultipleImagesCard.defaultProps = {
  blockColor: null,
  sm: 12,
  md: 12,
  lg: 12,
};

export default MultipleImagesCard;
