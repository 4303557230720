import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const ContentGetApp = ({ t }) => (
  <div className="division">
    <img
      className="icon"
      src={`${process.env.PUBLIC_URL}/img/page/app-icon.png`}
      alt="JOYAJOY"
    />
    <p className="app-name">{t('common.joyajoy')}</p>
    <div className="get-app-buttons">
      {/* <a
        href="https://itunes.apple.com/app/dipdip/id1099703101"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className="app-store"
          src={`${process.env.PUBLIC_URL}/img/page/app-store.png`}
          alt="AppStore"
        />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=com.dipdip"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className="play-store"
          src={`${process.env.PUBLIC_URL}/img/page/play-store.png`}
          alt="PlayStore"
        />
      </a> */}
      <a
        href="https://play.google.com/store/apps/details?id=com.dipdip"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className="play-store-apk"
          src={`${process.env.PUBLIC_URL}/img/page/download_button_APK.png`}
          alt="PlayStore"
        />
      </a>
    </div>
  </div>
);
ContentGetApp.propTypes = {
  t: PropTypes.func.isRequired,
};

export default (withTranslation('common')(ContentGetApp));
