import React from 'react';
import PropTypes from 'prop-types';
import hash from 'hash-sum';

const ContentText = ({ t, lines, device }) => (
  <div className={device ? `division ${device}` : 'division'}>
    {lines.map((line) => {
      const {
        text,
        styleType = 'text',
        color,
        fontWeight,
        fontSize,
        lineHeight,
        textAlign,
      } = line;
      return (
        <p
          className={styleType}
          key={hash(line)}
          style={{
            color,
            fontWeight,
            fontSize,
            lineHeight: lineHeight || (fontSize ? 1.5 : undefined),
            textAlign,
          }}
        >
          {text ? t(text) : <br />}
        </p>
      );
    })}
  </div>
);

ContentText.propTypes = {
  t: PropTypes.func.isRequired,
  lines: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    styleType: PropTypes.oneOf([
      'header',
      'subheader',
      'text-header',
      'text',
      'big-text',
    ]),
    color: PropTypes.string,
    fontWeight: PropTypes.oneOf(['100', '300', '400', '500', '700', '900']),
    fontSize: PropTypes.string,
    lineHeight: PropTypes.string,
    textAlign: PropTypes.oneOf(['left', 'right', 'center', 'justify']),
  })).isRequired,
  device: PropTypes.oneOf([undefined, 'normal', 'mobile']),
};

ContentText.defaultProps = {
  device: undefined,
};

export default ContentText;
