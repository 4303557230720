import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';
import hash from 'hash-sum';

import ContentText from './ContentText';
import ContentList from './ContentList';
import ContentImage from './ContentImage';
import ContentCarousel from './ContentCarousel';
import ContentVideo from './ContentVideo';
import ContentButton from './ContentButton';
import ContentLink from './ContentLink';
import ContentGetApp from './ContentGetApp';
import ContentForm from './ContentForm';

const contentComponents = {
  text: ContentText,
  list: ContentList,
  image: ContentImage,
  carousel: ContentCarousel,
  video: ContentVideo,
  button: ContentButton,
  link: ContentLink,
  getApp: ContentGetApp,
  form: ContentForm,
};

const ContentCard = ({
  path,
  t,
  backgroundColor,
  bodyBackgroundColor,
  sm,
  md,
  lg,
  icon,
  title,
  subtitle,
  divisions,
}) => (
  <Row style={{ backgroundColor }}>
    <Col sm={(12 - sm) / 2} md={(12 - md) / 2} lg={(12 - lg) / 2} />
    <Col sm={sm} md={md} lg={lg}>
      <Card className="page-content">
        <CardBody
          className="no-shadow"
          style={{ backgroundColor: bodyBackgroundColor || backgroundColor }}
        >
          {icon && (
            <ContentImage path={path} t={t} {...icon} styleType="icon" />
          )}
          {title && (
            <h2
              className={`division ${title.styleType || 'title'}`}
              style={{
                color: title.color,
                fontWeight: title.fontWeight,
                fontSize: title.fontSize,
                lineHeight:
                  title.lineHeight || (title.fontSize ? 1.5 : undefined),
                textAlign: title.textAlign,
              }}
            >
              {t(title.text)}
            </h2>
          )}
          {subtitle && (
            <h3
              className={`division ${subtitle.styleType || 'subtitle'}`}
              style={{
                color: subtitle.color,
                fontWeight: subtitle.fontWeight,
                fontSize: subtitle.fontSize,
                lineHeight:
                  subtitle.lineHeight || (subtitle.fontSize ? 1.5 : undefined),
                textAlign: subtitle.textAlign,
              }}
            >
              {t(subtitle.text)}
            </h3>
          )}
          {divisions.map(({ type, ...data }) => {
            const Component = contentComponents[type];
            return Component ? (
              <Component
                key={hash({ type, ...data })}
                path={path}
                t={t}
                {...data}
              />
            ) : null;
          })}
        </CardBody>
      </Card>
    </Col>
    <Col sm={(12 - sm) / 2} md={(12 - md) / 2} lg={(12 - lg) / 2} />
  </Row>
);

ContentCard.propTypes = {
  path: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  backgroundColor: PropTypes.string,
  bodyBackgroundColor: PropTypes.string,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  // eslint-disable-next-line react/require-default-props
  icon: PropTypes.shape({}),
  // eslint-disable-next-line react/require-default-props
  title: PropTypes.shape({
    text: PropTypes.string.isRequired,
    styleType: PropTypes.oneOf(['title']),
    color: PropTypes.string,
    fontWeight: PropTypes.oneOf(['100', '300', '400', '500', '700', '900']),
    fontSize: PropTypes.string,
    lineHeight: PropTypes.string,
    textAlign: PropTypes.oneOf(['left', 'right', 'center', 'justify']),
  }),
  // eslint-disable-next-line react/require-default-props
  subtitle: PropTypes.shape({
    text: PropTypes.string.isRequired,
    styleType: PropTypes.oneOf(['subtitle']),
    color: PropTypes.string,
    fontWeight: PropTypes.oneOf(['100', '300', '400', '500', '700', '900']),
    fontSize: PropTypes.string,
    lineHeight: PropTypes.string,
    textAlign: PropTypes.oneOf(['left', 'right', 'center', 'justify']),
  }),
  divisions: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.oneOf([
      'text',
      'list',
      'image',
      'carousel',
      'video',
      'button',
      'link',
      'getApp',
      'form',
    ]),
  })),
};

ContentCard.defaultProps = {
  backgroundColor: '#FFFFFF',
  bodyBackgroundColor: '',
  sm: 12,
  md: 12,
  lg: 12,
  divisions: [],
};

export default ContentCard;
